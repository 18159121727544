const mapping: Partial<Record<string, string>> = {
  PACIFICA: "/static/img/logos/pacifica.png",
  MAIF: "/static/img/logos/maif.png",
  "GROUPAMA PARIS VAL DE LOIRE": "/static/img/logos/groupamapvl.png",
  "GROUPAMA RHONE ALPES AUVERGNE": "/static/img/logos/groupamarra.png",
  "GROUPAMA CENTRE MANCHE": "/static/img/logos/groupamacm.png",
  "GROUPAMA LOIRE BRETAGNE": "/static/img/logos/groupamalb.png",
  "GROUPAMA MEDITERRANEE": "/static/img/logos/groupamamed.png",
  "AXA FRANCE": "/static/img/logos/axa.png",
  "AXA GROUPEMENT": "/static/img/logos/axa.png",
  "AXA - EVEN": "/static/img/logos/axa.png",
  "DIRECT ASSURANCE": "/static/img/logos/directassurance.png",
  "L'OLIVIER ASSURANCE": "/static/img/logos/lolivier.png",
  "GAN ASSURANCES": "/static/img/logos/gan.png",
  "ALLIANZ IARD": "/static/img/logos/allianz.png",
  "ALLIANZ VIE": "/static/img/logos/allianz.png",
  "ABEILLE ASSURANCES": "/static/img/logos/abeille.png",
  AXERIA: "/static/img/logos/axeria.png",
  CMAM: "/static/img/logos/cmam.png",
  "MUTUELLE SAINT CHRISTOPHE": "/static/img/logos/msc.png",
  SOGESSUR: "/static/img/logos/sogessur.png",
  WAKAM: "/static/img/logos/wakam.png",
  "GROUPAMA DACIA": "/static/img/logos/groupama.png",
  "GROUPAMA RENAULT": "/static/img/logos/groupama.png",
  SWISSLIFE: "/static/img/logos/swisslife.png",
  "LA MEDICALE": "/static/img/logos/lamedicale.png",
  "MUTUELLE FRATERNELLE D'ASSURANCES": "/static/img/logos/mfa.png",
  "SERENIS ASSURANCES": "/static/img/logos/serenis.png",
  "MUTUELLE DE L'EST-ASSURONE": "/static/img/logos/mea.png",
  "ASSURANCES DU CREDIT MUTUEL": "/static/img/logos/acm.png",
  "THELEM ASSURANCES": "/static/img/logos/thelem.png",
  "AREAS ASSURANCES": "/static/img/logos/areas.png",
  "GROUPAMA GRAND EST": "/static/img/logos/groupamage.png",
  "GROUPAMA NORD EST": "/static/img/logos/groupamane.png",
  "GROUPAMA D'OC": "/static/img/logos/groupamaoc.png",
  "MAAF ASSURANCES": "/static/img/logos/maaf.png",
  "MMA IARD": "/static/img/logos/mma.png",
  "GMF ASSURANCES": "/static/img/logos/gmf.png",
};
export default mapping;
